<template>
    <WrapperVisualization
        :sidebarData="getSidebarData(visualizationData)"
        :selectedModuleId="selectedModuleId"
        :setModuleId="setModuleId"
        @onChangeLevelClearance="value => levelClearance = value"
    >
        <template #header>
            <VisualizationHeader 
                :scenarios="selectedScenario"
                :properties="properties"
                :selectedPropertiesId="selectedPropertiesId"
                :getPropertyCollection="getPropertyCollection"
                :parameterId="parameterId"
                @onSelectProperty="id => selectedPropertiesId = id"
            />
        </template>
        <div v-if="selectedModuleId" class="flex w-full overflow-x-auto">
            <SystemContent
                :module="selectedModule"
                :levelClearance="levelClearance"
                class="mt-20"
            />
            <PropertyVisualization
                class="mt-14"
                :filteredCollections="getFilteredCollections(selectedModule)"
                :properties="getPropertyCollection(selectedPropertiesId)"
                :getParameter="getParameter"
                :selectedPropertiesId="selectedPropertiesId"
            />
        </div>
    </WrapperVisualization>
</template>

<script setup>
    import { ref } from 'vue';
    import SystemContent from '@/components/shared/visualization/systemContent/Index'
    import WrapperVisualization from '@/elements/layout/WrapperVisualization'
    import PropertyVisualization from '@/components/roadmap/visualization/PropertyVisualization'
    import VisualizationHeader from './VisualizationHeader'
    
    import header from '@/components/roadmap/visualization/Header.vue'
    import useWidgetVisualization from '@/composable/useWidgetVisualization'
    import useScenario from '@/store/modules/programmatic/useScenario'
    import { getParameterTitles, getParameterTextDefinition, getParameterValue, getParameterBg} from '@/store/modules/roadmap/services.js'

    const parameterId = ref(null)

    const { getFilteredCollections } = useWidgetVisualization()

    const { 
        visualizationData,
        getSidebarData,
        selectedModuleId,
        setModuleId,
        selectedModule,
        scenarios,
        properties,
        selectedPropertiesId,
        getPropertyCollection,
        selectedScenario,
        getParameter
    } = useScenario()

    const levelClearance = ref(4);
</script>


<style>
    .logic-cell-width{
        width: 230px
    }
    .widget-parameter {
    /* width: 303px; */
    /* min-height: 30px; */
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-top: 0.5px solid #fff;
    border-bottom: 0.5px solid #fff;
    /* padding-left: 18px; */
    color: #fff;
}
</style>